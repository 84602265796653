import useMainView from "../../hooks/useMainView";
import ProductSelector from "../../routes/Main/ProductSelector/ProductSelector";
import settings from "../../static/settings.svg";
import open from "../../static/open.png";
import ProductNav from "../../routes/Main/ProductNav/ProductNav";
import ViewManager from "../ViewManager/ViewManager";
import useViewMode from "../../hooks/useViewMode";
import { useEffect, useState } from "react";
import Settings from "../Settings/Settings";
import ModalWindow from "../ModalWindow/ModalWindow";
import Footer from "../../routes/Footer/Footer";
import PricingHistory from "../../routes/PricingHistory/PricingHistory";
import ChartManager from "./ChartManager";
import HistoryMini from "../HistoryMini/HistoryMini";
import { useDispatch, useSelector } from "react-redux";
import { set_tab_option } from "../../redux/Common/actions";
import Container from "../Container/Container";
import { useParams } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";
import useRestoreSnapshot from "../../hooks/snapshots/useRestoreSnapshot";
import Loader from "../Loader/Loader";
import LoaderWindow from "../LoaderWindow/LoaderWindow";
import loader from "./../../static/load.png";

const MainViewManager = () => {
  const mainView = useMainView();
  const dispatch = useDispatch();
  const viewManager = useViewMode();
  const products = viewManager.viewModes;
  const [mode, setMode] = useState(0);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const tab_option = useSelector((state) => state.commonReducer.tabOption);

  // Импортируем хуки для восстановления данных
  const { restore } = useRestoreSnapshot();
  const { iid } = useParams();
  const queryParams = useQueryParams();
  const [isRestored, setIsRestored] = useState(false);

  // Асинхронное восстановление данных
  useEffect(() => {
    async function restoreData() {
      if (iid !== undefined) {
        await restore(iid);
      } else if (queryParams.get("restore") !== null) {
        await restore(queryParams.get("restore"));
      }
      setIsRestored(true);
    }
    restoreData();
  }, [iid, queryParams, restore]);

  useEffect(() => {
    setMode(tab_option);
    console.log("tab_option изменился:", tab_option);
  }, [tab_option]);

  useEffect(() => {
    dispatch(set_tab_option(mode));
    console.log("mode изменился:", mode);
  }, [mode, dispatch]);

  // Пока данные не восстановлены, показываем загрузку
  if (!isRestored) {
    return (
      <div className={"loader-window"}>
        <div className={"loader-window_content"}>
          <div className="loader-window_content-image">
            <img src={loader} alt="" />
          </div>
          <h3>Loading ...</h3>
        </div>
      </div>
    );
  }

  if (mainView.mainView === "default") {
    return (
      <Container>
        <div className="main-container">
          <div className="main-content">
            <div className="main-content-selection">
              <div className="product-selector-block">
                <ModalWindow
                  content={<Settings />}
                  isActive={isSettingsOpen}
                  setIsActive={setIsSettingsOpen}
                  smallSize
                />
                <ModalWindow
                  content={
                    <PricingHistory
                      isActive={isHistoryOpen}
                      setIsActive={setIsHistoryOpen}
                    />
                  }
                  isActive={isHistoryOpen}
                  setIsActive={setIsHistoryOpen}
                />
                <ProductSelector
                  products={products}
                  productSelected={viewManager.viewMode}
                  setProductSelected={(value) => {
                    viewManager.setViewMode(value);
                    setMode(0);
                  }}
                />
                <div
                  className="settings_desktop"
                  onClick={() => setIsSettingsOpen(true)}
                >
                  <img
                    className="settings_img"
                    src={settings}
                    alt="иконка настроек"
                  />
                  <p className="setting_word">Settings</p>
                </div>
              </div>
              <ProductNav mode={mode} setMode={setMode} />
              <ViewManager mode={mode} />
            </div>
            <Footer />
          </div>
          <div className="mini-history">
            <HistoryMini close={setIsHistoryOpen} />
          </div>
          <div
            className="button-pricing-history"
            onClick={() => setIsHistoryOpen(true)}
          >
            <img src={open} alt="" />
          </div>
        </div>
      </Container>
    );
  } else if (mainView.mainViews.includes(mainView.mainView.toUpperCase())) {
    return <ChartManager />;
  }

  return null;
};

export default MainViewManager;
